import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/shared/shared.module';
import { LoginComponent } from './login/login.component';
import { MaterialModule } from './MaterialModule';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    MatIconModule,
    BrowserAnimationsModule,
    HttpClientModule,

]
})
export class AuthModule { }
