<div class="loginPage" *ngIf="!showOtpPage">

    <mat-card fxFlex.gt-sm="33" fxFlex.sm="50" fxFlex.lt-sm="80" style="padding: 2rem;">
        <mat-card-title class="title">

            <img src="assets/images/farm_sens_ai.svg" width="175" height="120">

        </mat-card-title>
        <mat-card-content class="content">
            <form fxFlex="100" [formGroup]="loginPage" fxLayout="column" fxLayoutGap="5">

                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>Login Name</mat-label>
                        <input appSanitize type="text" autocomplete="username" matInput formControlName="user_name"
                            [maxLength]="25"
                            oninput="this.value = this.value.trimStart().replace(/[^a-zA-Z0-9#!~\-_.]/g, '').replace(/\s+/g, ' ');">
                        <mat-icon style="color: #BCBCBC;cursor: default;" matSuffix>person</mat-icon>
                        <mat-error
                            *ngIf="loginPage.controls['user_name'].hasError('required') && loginPage.controls['user_name'].touched">
                            login name is required
                        </mat-error>
                    </mat-form-field>

                </div>

                <div>
                    <mat-form-field appearance="outline">
                        <mat-label>Password</mat-label>
                        <input matInput (ngModelChange)="hidePassword()" autocomplete="current-password" [type]="hide ? 'password'
                            : 'text'" formControlName="pass">
                        <mat-icon style="color: #BCBCBC;" matSuffix (click)="showHidePassword()"
                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">{{hide ?
                            'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-error>
                            <div
                                *ngIf="loginPage.controls['pass'].hasError('required') && loginPage.controls['pass'].touched">
                                password is required
                            </div>
                            <div *ngIf="loginPage.controls['pass'].hasError('pattern')">
                                password is invalid
                            </div>
                        </mat-error>

                    </mat-form-field>

                </div>


                <div fxLayout="column" fxLayoutGap="20">
                    <div>
                        <button class="w-100 m-t-20 submit grow" style="min-height: 2.5rem; background: linear-gradient(90deg, #002D5D 0%, #01496D 100%);" (click)="login()"
                            mat-raised-button>Login</button>
                    </div>
                    <div fxLayoutAlign="end center" style="margin-top: 2rem">

                        <a style="color:#002D5D;text-decoration: none !important;"
                            routerLink="/auth/forgot-password">Forgot Password ?</a>
                    </div>
                </div>


            </form>
        </mat-card-content>
    </mat-card>

</div>

