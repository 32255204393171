import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Ornithon';
  private url = ['https://test.ornithon.co.in', 'https://ornithon.co.in'];
  constructor (private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer){
   
  }

  ngOnInit (): void {
    this.disableInspect();


  }

  disableInspect = () => {
    const is_prod_env = this.url.some((u: string) => window.location.href.includes(u));
    if (is_prod_env) {
      window.addEventListener('contextmenu', this.preventFn);
      document.addEventListener('keydown', this.preventFn);
    }
  };

  preventFn = (event: any) => {
    //to disable right click
    event.type == 'contextmenu' ? event.preventDefault() : NaN;
    //disable inspect tool 
    if (event.type == 'keydown') {
      const key = ["i", "j", "c", "f12"];
      (event.ctrlKey && event.shiftKey && key.includes(event.key.toLowerCase()))
        || event.key.toLowerCase() == "f12" ? event.preventDefault() : NaN;
    }
  };

}
