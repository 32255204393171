import { Component, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent {

  public subscription: Subscription[] = [];
  loginPage: FormGroup;

  public hide = true;
  public securedEmail: any;
  public is_otp_expired = false;
  private auth_data: any;
  public otp_expire_date!: string;
  public count_down: any;
  public otp: Array<any> = [];
  public stop_interval: any;
  otpFormGroup!: FormGroup;
  public rememberMe: any = false;
  public showOtpPage: boolean = false;
  public user_id: any;
  public secret_key_1: any;
  public secret_key_2: any;
  public hideVerifyBtn: boolean = false;
  constructor(
   private http : HttpClient,
   private router : Router

  ) {
    this.loginPage = new FormGroup({
      user_name: new FormControl('', Validators.required),
      pass: new FormControl('', [
        Validators.required])
    })

  }

  ngOnInit(): void {
    console.log('login')
  }

  hidePassword()
  {}

  login() {
    console.log("logging in")
    if (this.loginPage.invalid) {
      this.loginPage.markAllAsTouched();
      return;
    }
    this.http.post('https://devapi.ornithon.co.in/ornithon/authentication/getProductUrl',this.loginPage.value).
    subscribe(
      (res:any) => {
        if(res){
          window.location.href = res.url;

        }
      }
    )
    
  }

  showHidePassword(){
    if (this.loginPage.controls['pass'].value?.length) {
      this.hide = !this.hide;
    } else {
      this.hide = true;
    }
  }
}

